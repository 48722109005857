@import 'includes';

@font-face {
  font-family: 'PT Sans Narrow';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/pt-sans-narrow-v9-latin-regular.eot');
  src: local('PT Sans Narrow'), local('PTSans-Narrow'),
    url('assets/fonts/pt-sans-narrow-v9-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/pt-sans-narrow-v9-latin-regular.woff2') format('woff2'),
    url('assets/fonts/pt-sans-narrow-v9-latin-regular.woff') format('woff'),
    url('assets/fonts/pt-sans-narrow-v9-latin-regular.ttf') format('truetype'),
    url('assets/fonts/pt-sans-narrow-v9-latin-regular.svg#PTSansNarrow')
      format('svg');
}

@font-face {
  font-family: 'PT Sans Narrow';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/pt-sans-narrow-v9-latin-700.eot');
  src: local('PT Sans Narrow Bold'), local('PTSans-NarrowBold'),
    url('assets/fonts/pt-sans-narrow-v9-latin-700.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/pt-sans-narrow-v9-latin-700.woff2') format('woff2'),
    url('assets/fonts/pt-sans-narrow-v9-latin-700.woff') format('woff'),
    url('assets/fonts/pt-sans-narrow-v9-latin-700.ttf') format('truetype'),
    url('assets/fonts/pt-sans-narrow-v9-latin-700.svg#PTSansNarrow')
      format('svg');
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: 'PT Sans Narrow', sans-serif;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-main-gold;
  text-transform: uppercase;
}

h1 {
  font-size: 3rem;
  margin-top: 0;
  margin-bottom: 16px;
}

h3 {
  margin-bottom: -14px;
}

p,
a {
  font-weight: bold;
  font-size: 1.2rem;
  color: #5d5d5c;
}

a {
  text-decoration-color: transparent;
  transition: 300ms text-decoration-color ease-in-out;

  &:hover {
    text-decoration-color: #5d5d5c;
  }
}

div.divider {
  width: 100%;
  height: 1px;
  background: $color-main-gold;
  margin: 28px 0;
}

a.nav-arrow {
  display: block;
  width: 80px;
  height: 80px;
  position: absolute;
  top: calc(50% - 40px);
  transition: 300ms transform ease-in-out;

  &.left {
    background: url(/assets/img/arrow-left.png) no-repeat center center;
    background-size: cover;
    left: -75px;
  }

  &.right {
    background: url(/assets/img/arrow-right.png) no-repeat center center;
    background-size: cover;
    right: -75px;
  }

  &:hover {
    transform: scale(1.1);
  }
}

.content-wrapper {
  display: flex;
  height: 100%;

  aside {
    width: 50%;
    background: url(/assets/img/image1.jpeg) no-repeat center bottom;
    background-size: cover;
  }

  main {
    width: calc(50% - 70px);
    padding: 35px;
    position: relative;
  }
}

@media (max-width: 1049px) {
  a.nav-arrow.left,
  a.nav-arrow.right {
    left: calc(50% - 40px);
    right: initial;
    top: calc(100% + 40px);
    bottom: -30px;
  }
}

@media (max-width: 799px) {
  .content-wrapper {
    display: flex;
    flex-direction: column;

    aside {
      width: 100%;
      height: 300px;
      background-position-y: center;
    }

    main {
      width: 100%;
      padding: 35px;
      box-sizing: border-box;
    }
  }
}
